/* !
 * index.js v1.0.0
 * Copyright, Connecty Inc.
 */
((FUNCTIONS) => {
	const a = 'is-active';
	const v = 'is-visible';

	const countCasestudy = () => {
		const list = document.querySelectorAll(".js-casestudy-count");
		if (list) {
			list.forEach((ele, index) => {
				
				
				var caseNumber = String(index + 1).padStart(2, '0');
				ele.textContent = `CASE ${caseNumber}`;
			});
		}
	}

	const hideFixedBtn = () => {
		if (document.querySelector('.top-fixed-bnr-close-btn')) {
			document.querySelector('.top-fixed-bnr-close-btn').addEventListener('click', function () {
				this.parentElement.style.display = 'none';
			});
		}
	}
	hideFixedBtn();

	const mainVisSwiper = () => {
		const mvList = document.querySelectorAll(".js-top-mv");
		if (mvList.length > 0) {

			const destroyFlg = (mvList.length === 1) ? true : false;

			const splide = new Splide('.top-mainVis.splide', {
				type: 'fade',
				autoplay: true,
				pauseOnHover: false,
				pauseOnFocus: false,
				rewind: true,
				perPage: 1,
				interval: 5000,
				speed: 500,
				arrows: false,
				pagination: true,
				paginationKeyboard: true,
				destroy: destroyFlg,
			}).mount();

			if (destroyFlg) {
				const element = document.querySelector(".top-mainVis .splide__toggle");
				if (element) {
					element.classList.add("di_none");
				}
			}
		}
	};

	const newsSlide = () => {
		if (document.querySelector('.js-top-slide01')) {
			window.addEventListener('load', function () {
				let slider = document.querySelector('.js-top-slide01');
				let slideCount = slider.querySelectorAll('.splide__slide').length;

				if (slideCount > 1) {
					const splide = new Splide('.js-top-slide01', {
						type: 'loop',
						perPage: 1,
						autoplay: true,
						autoWidth: true,
						focus: "center",
						updateOnMove: true,
						interval: 5000,
						speed: 500,
						arrows: true,
						pagination: true,
						paginationKeyboard: true,
						perMove: 1,
						breakpoints: {
							1024: {
								perPage: 1,
								gap: 40,
							},
						}
					}).mount();
					if (document.querySelector('.js-top-slide01 .splide__arrow')) {
						const arrowpos = () => {
							document.querySelectorAll('.js-top-slide01 .splide__arrow').forEach(ele => {
								if (document.querySelector('.is-active.is-visible .top-slide01-item-img')) {
									let postop = (document.querySelector('.is-active.is-visible .top-slide01-item-img').clientHeight - ele.clientHeight) / 2;
									ele.style.top = postop + 'px';
								}
								ele.style.opacity = 1;
							});
						};
						const pagenationpos = () => {
							document.querySelectorAll('.js-top-slide01 .splide__track').forEach(ele => {
								let eleHeight = ele.clientHeight;
								ele.style.minHeight = eleHeight + 'px';
							});
						};
						setTimeout(() => {
							window.addEventListener('resize', (e) => {
								// リサイズ時の処理
								arrowpos();
								pagenationpos();
							});
							// リサイズイベントを即時に発火
							window.dispatchEvent(new Event('resize'));
						}, 500);
					}
				}
			});

		}

		if (document.querySelectorAll('.js-top-slide02')) {
			document.querySelectorAll('.js-top-slide02').forEach(ele => {
				let slideCount = ele.querySelectorAll('.splide__slide').length;
				if (slideCount > 1) {
					const splide = new Splide(ele, {
						type: 'loop',
						perPage: 3,
						autoplay: true,
						autoWidth: true,
						focus: "center",
						updateOnMove: true,
						interval: 5000,
						speed: 500,
						arrows: true,
						pagination: true,
						paginationKeyboard: true,
						perMove: 1,
						destroy: true,
						breakpoints: {
							1024: {
								destroy: false,
								perPage: 1,
								perMove: 1,
								gap: 40,
							},
						}
					}).mount();

					if (ele.querySelector('.splide__arrow')) {
						const arrowpos = () => {
							ele.querySelectorAll('.splide__arrow').forEach(ele => {
								if (ele.parentNode.parentNode.querySelector('.is-active.is-visible figure')) {
									let postop = (ele.parentNode.parentNode.querySelector('.is-active.is-visible figure').clientHeight - ele.clientHeight) / 2;
									ele.style.top = postop + 'px';
								}
								ele.style.opacity = 1;
							});
						};
						setTimeout(() => {
							window.addEventListener('resize', (e) => {
								// リサイズ時の処理
								arrowpos();
							});
							// リサイズイベントを即時に発火
							window.dispatchEvent(new Event('resize'));
						}, 500);
					}
				}

			});


		}
	};

	const toggleFixedBnr = () => {
		let footer = document.querySelector('.footer');
		let bnr = document.querySelector('.top-fixed-bnr');

		if (bnr) {
			let footerPos = footer.offsetTop;
			let stopPos = footerPos - 400;

			window.addEventListener('scroll', (e) => {

				if (window.scrollY > document.querySelector('.top-content-01').offsetTop - 299) {
					if (window.scrollY >= stopPos) {
						bnr.classList.remove(v);
					} else {
						bnr.classList.add(v);
					}
				} else {
					bnr.classList.remove(v);
				}

			});
		}
	}

	const inViewAnimation = () => {
		const target = document.querySelectorAll('.js-top-inviewAnimation');
		const options = {
			root: null,
			rootMargin: '-50% 0px',
			threshold: 0
		};
		const callback = (entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					entry.target.classList.add(v);
				}
			});
		};
		const observer = new IntersectionObserver(callback, options);

		target.forEach(ele => {
			observer.observe(ele);
		});
	}

	const addClassPickupList = () => {
		if (!document.querySelector('.js-pickup-list')) return;

		// 対象のli要素を取得
		const pickupItems = document.querySelectorAll('.js-pickup-list ul.splide__list li');

		

		// li要素が1件だけの場合
		if (pickupItems.length === 1) {
			pickupItems[0].classList.add('_single');
		}
	}

	countCasestudy();
	newsSlide();
	mainVisSwiper();
	toggleFixedBnr();
	inViewAnimation();
	addClassPickupList();
	(() => {
		const target = document.querySelectorAll('.js-anim-circle .splide__pagination__page');
		target.forEach(ele => {
			ele.innerHTML = '<svg class="js-dots-circle"><circle cx="12" cy="12" r="9.5"></circle></svg>'
		});
	})();
})(window.FUNCTIONS);
